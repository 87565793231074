export const keyword = {
  list: '关键字列表',
  from: '关键字操作',

  create: '添加关键字',
  view: '查看关键字',

  title: '关键字标题',
  total: '搜索次数',
  update_time: '最新搜索时间',

  rules: {
    value: {
      require: '关键字标题不能为空',
    },
  },

  global: {
    from: '全局关键字',
  },

  begin: {
    from: '起始港关键字',
  },

  end: {
    from: '目的港关键字',
  },
}
