export const organization = {
  list: '机构列表',
  from: '机构操作',
  view: '机构详情',
  info: '机构信息',

  logo: '机构Logo',
  title: '机构名称',
  valid_time: '有效期',
  register_limit: '员工注册上限',
  create_time: '注册时间',

  business_license: '营业执照',
  manager_name: '机构法人姓名',
  mobile: '机构电话',
  address: '机构地址',

  rules: {
    logo: {
      require: '机构Logo不能为空',
    },
    title: {
      require: '机构名称不能为空',
    },
    register_limit: {
      require: '员工注册数不能为空',
    },
    valid_time: {
      require: '有效期不能为空',
    },
    manager_name: {
      require: '机构法人姓名不能为空',
    },
  },

  audit: {
    info: '机构审核',

    title: '机构审核',
    content: '审核意见',

    rules: {
      audit_status: {
        require: '审核状态不能为空',
      }
    }
  },

  member: {
    from: '机构会员操作',
    info: '机构会员',

    rules: {
      mobile: {
        require: '机构会员手机号码不能为空',
      },
    }
  },

  log: {
    info: '操作日志',

    user_info: '用户信息',

    content: '操作内容',
    create_time: '操作时间',
  }
}
