// ---------------------------------------

// 公共
import { common } from './system/platform/common/zh'
// 核心
import { kernel } from './system/platform/kernel/zh'


// ---------------------------------------
// 模块

import { organization } from './module/platform/organization/zh'

import { member } from './module/platform/member/zh'

import { role } from './module/platform/role/zh'

import { contract } from './module/platform/contract/zh'

import { advertising } from './module/platform/advertising/zh'

import { keyword } from './module/platform/keyword/zh'

import { business } from './module/platform/business/zh'


export default {
  // 公共
  common: {
    ...common,
  },

  // 核心
  kernel: {
    ...kernel
  },


  // ------------------------------------

  // 广告
  advertising: {
    ...advertising,
  },

  // 机构
  organization: {
    ...organization,
  },

  // 会员
  member: {
    ...member,
  },

  // 身份
  role: {
    ...role,
  },

  // 合约
  contract: {
    ...contract
  },

  // 热门关键字
  keyword: {
    ...keyword
  },

  // 业务
  business: {
    ...business
  }
}
