/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 系统信息存储器
 */
export const getSystemInfo = defineStore("getSystemInfo", {
  state: () => ({
    webinfo: [],
  }),
  getters: {
    website_logo: state => state.webinfo.logo,
    website_chinese_title: state => state.webinfo.web_chinese_name,
    website_chinese_short_title: state => state.webinfo.web_chinese_short_name,
    website_english_title: state => state.webinfo.web_english_name,
    website_description: state => state.webinfo.description,
    copyright: state => state.webinfo.copyright,
  },
  actions: {
    async initSystemInfo() {
      if(Object.keys(this.webinfo).length){
        return
      }
      await $http({
        url: $http.adornUrl(`/kernel`),
        method: 'post',
        params: $http.adornParams(),
      }).then(({ data }) => {
        if (data && data.status === 200) {
          this.webinfo = data.data
        }
      })
    },
    clearData() {
      this.webinfo = []
    },
  },
  persist: {
    key: "systemInfoStorage", // 持久化存入的key
    storage: localStorage // 持久化方式
  }
});

