export const kernel = {
  common: {
    category: '分类',
  },

  login: {
    account_login: '账户登录',
    sms_login: '短信登录',
    scan_login: '扫码登录',

    title: '协呈服务',
    description: '欢迎您使用本系统',
    login: '登 录',
    username: '登录用户',
    password: '登录密码',
    verification_code: '验证码',
    keep: '记住我',
    agreed: '我已同意',
    agreement: '《系统用户使用协议》',
    login_success: '登录成功',
    forget: '忘记了密码 ? ',
    oauth: '第三方登录',

    rules: {
      username: {
        require: '登录账户不能为空',
      },
      password: {
        require: '登录密码不能为空',
      },
      verification_code: {
        require: '验证码不能为空',
        error: '验证码错误',
      },
    },
  },

  user: {
    list: '管理员列表',
    from: '管理员操作',
    create: '添加管理员',
    update: '修改管理员',
    view: '管理员详情',
    delete: '删除管理员',
    export: '导出管理员',

    reset: '重置密码',
    change: '修改密码',

    nickname: '用户姓名',
    avatar: '用户头像',
    account: '登录账户',
    username: '登录账户',
    realname: '真实名称',
    email: '联系邮箱',
    mobile: '联系号码',
    status: '用户状态',
    user_role_name: '用户角色',
    last_login_time: '最后登录时间',
    create_time: '注册时间',

    please_mobile: '请使用手机号作为用户账号',

    rules: {
      username: {
        require: '登录账户不能为空',
        length: '长度在 1 到 32 个字符',
      },
      nickname: {
        require: '用户昵称不能为空',
        length: '长度在 1 到 32 个字符',
      },
      avatar: {
        require: '用户头像不能为空',
      },
      role_id: {
        require: '用户角色不能为空',
      },
    },

    password: {
      from: '密码修改',

      old_password: '当前密码',
      password: '新密码',
      password_confirmation: '确认密码',
      please_old_password: '请输入当前登录密码',
      rules: {
        old_password: {
          require: '当前登录密码不能为空',
          length: '长度在 1 到 32 个字符',
        },
        password: {
          require: '新密码不能为空',
          length: '长度在 1 到 32 个字符',
        },
        password_confirmation: {
          require: '确认密码不能为空',
          length: '长度在 1 到 32 个字符',
        },
      },
    },

    center: {
      title: '用户信息',
      description: '用户描述',

      account: {
        title: '个人信息',
      },
      password: {
        title: '个人密码',
      },
      login: {
        title: '登录记录',

        browser: '浏览器',
        system: '登录系统',
        location: '登录地点',
        ip: 'IP',
      },
    },
  },

  role: {
    list: '角色列表',
    from: '角色操作',
    title: '角色名称',
    content: '角色描述',
    distribution: '分配角色',

    menu: '菜单权限',
    interface: '接口权限',

    rules: {
      title: {
        require: '角色名称不能为空',
        length: '长度在 1 到 50 个字符',
      },
    },
  },


  menu: {
    list: '菜单列表',
    from: '菜单操作',

    title: '菜单标题',
    content: '菜单描述',
    parent_title: '上级菜单',
    icon: '菜单图标',
    category_info: '菜单分类',
    type: '菜单类型',
    url: '菜单地址',
    is_hidden: '是否隐藏',

    rules: {
      title: {
        require: '菜单名称不能为空',
        length: '长度在 1 到 50 个字符',
      },
      url: {
        require: '菜单地址不能为空',
      },
    },

    category: {
      list: '分类列表',
      from: '分类操作',
      info: '分类',

      title: '分类标题',
      icon: '分类图标',
      sort: '分类排序',

      rules: {
        title: {
          require: '菜单分类不能为空',
          length: '长度在 1 到 50 个字符',
        },
      },
    },
  },



  dictionary: {
    list: '字典列表',
    from: '字典操作',

    name: '字典名称',
    title: '字典标题',
    content: '字典内容',
    description: '字典描述',

    rules: {
      name: {
        require: '字典名称不能为空',
        length: '长度在 1 到 20 个字符',
      },
      title: {
        require: '字典标题不能为空',
        length: '长度在 1 到 50 个字符',
      },
      content: {
        require: '字典内容不能为空',
      },
    },

    parameter: {
      list: '字典数据列表',
      from: '字典数据操作',
      info: '数据',

      title: '字典数据标题',
      value: '字典数据内容',

      rules: {
        title: {
          require: '字典数据标题不能为空',
        },
        value: {
          require: '字典数据内容不能为空',
        },
      }
    }
  },


  config: {
    list: '配置列表',
    from: '配置操作',
    name: '配置名称',
    title: '配置标题',
    category_title: '分类标题',
    type: '配置类型',
    value: '配置值',
    params: '配置参数',
    content: '配置描述',

    active: '开启',
    inactive: '关闭',

    rules: {
      name: {
        require: '配置名称不能为空',
        length: '长度在 1 到 50 个字符',
      },
      title: {
        require: '配置标题不能为空',
        length: '长度在 1 到 50 个字符',
      },
      type: {
        require: '配置类型不能为空',
      },
      value: {
        require: '配置值不能为空',
      },
    },

    web_config: {
      web_name: '网站名称',
      web_url: '网络链接',
      keywords: '网站关键字',
      description: '网站描述',
      logo: '网站logo',
      mobile: '联系电话',
      email: '联系邮箱',
      icp: '网站备案号',
      web_status: '网站状态',
      web_clone_info: '网站关闭原因',
    },

    category: {
      list: '分类列表',
      from: '分类操作',
      create: '添加配置',
      view: '查看配置',
      info: '分类',

      name: '分类名称',
      title: '分类标题',
      parent_title: '上级分类',
      content: '分类描述',
      depth: '分类层级',

      rules: {
        name: {
          require: '分类名称不能为空',
          length: '长度在 1 到 50 个字符',
        },
        title: {
          require: '分类标题不能为空',
          length: '长度在 1 到 50 个字符',
        },
      },
    },
  },

  profile: {
    list: '资料列表',
    from: '资料操作',

    type: '资料类型',
    name: '资料名称',
    title: '资料标题',
    content: '资料内容',

    rules: {
      type: {
        require: '资料类型不能为空',
      },
      name: {
        require: '资料名称不能为空',
      },
      title: {
        require: '资料标题不能为空',
      },
      content: {
        require: '资料内容不能为空',
      },
    },
  },

  notice: {
    list: '通知列表',
    from: '通知操作',
    view: '查看通知',
    send: '发送',

    content: '通知内容',
    delivery_status: '推送状态',

    rules: {
      content: {
        require: '通知内容不能为空',
      },
      category_id: {
        require: '通知分类不能为空',
      },
    },

    // 通知分类
    category: {
      list: '分类列表',
      from: '分类操作',

      title: '分类标题',

      rules: {
        title: {
          require: '分类标题不能为空',
        }
      },
    },
  },

  message: {
    list: '消息列表',
    from: '消息操作',
    title: '消息标题',
    content: '消息内容',
    receiver: '接收者',
    author: '发送者',
    type: '消息类型',
    send_time: '发送时间',

    user: '用户',
    role: '角色',
    accept_role: '接收角色',
    rules: {
      title: {
        require: '标题不能为空',
        length: '长度在 1 到 100 个字符',
      },
      type: {
        require: '消息类型不能为空',
      },
      content: {
        require: '内容不能为空',
        length: '长度在 1 到 1000 个字符',
      },
      accept: {
        require: '接受者不能为空',
      },
    },
  },

  log: {
    action: {
      list: '行为日志列表',
      view: '行为日志详情',
    },

    operate: {
      list: '操作日志列表',
      view: '操作日志详情',
    },

    username: '操作人姓名',
    operation: '操作行为',
    browser: '浏览器信息',
    system: '操作系统信息',
    address: '所在地信息',
    create_time: '操作时间',
  },

  setting: {
    center: '设置中心',
  },
}
