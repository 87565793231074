export const member = {
  list: '会员列表',
  from: '会员操作',
  info: '会员信息',
  view: '会员详情',
  delete: '删除会员',

  mini_code: '小程序码',
  mini_placard: '短海报',
  placard: '长海报',

  avatar: '会员头像',
  username: '登录账户',
  mobile: '手机号码',
  email: '电子邮箱',
  nickname: '会员昵称',
  market_status: '销售状态',
  status: '账号状态',
  role_title: '会员身份',
  valid_time: '会员有效期',
  organization_info: '机构信息',
  last_login_time: '最后登录时间',
  valid_time: '剩余有效时间',
  create_time: '注册时间',

  invoice: {
    list: '发票列表',
    from: '发票审核',

    nickname: '会员信息',
    category: '抬头类型',
    type: '发票类型',
    name: '发票抬头',
    code: '统一社会信用代码',
    email: '用户邮箱',
    mobile: '用户电话',
    audit_status: '审核状态',

    rules: {
      audit_status: {
        require: '审核状态不能为空',
      }
    },
  },

  distribution: {
    list: '分销列表',
    from: '分销审核',
    info: '会员分销',

    nickname: '会员信息',
    money: '分销金额',
    audit_status: '审核状态',

    rules: {
      audit_status: {
        require: '审核状态不能为空',
      }
    },
  },

  order: {
    list: '订单列表',
    view: '订单详情',

    order_no: '订单号',
    member_info: '会员信息',
    contract_info: '套餐信息',
    pay_money: '支付金额',
    pay_type: '支付方式',
    pay_status: '支付状态',
    order_status: '订单状态',
    pay_time: '支付时间',
    create_time: '创建时间',
  },

  behavior: {
    list: '行为列表',

    normal_schedule_total: '普货船期查询',
    container_schedule_total: '集装箱船期查询',
    ship_search_total: '船舶查询',
    article_browse_total: '资讯浏览',
    global_serach_total: '全局搜索',
    invitation_total: '会员邀请',
    share_total: '会员分享',
    contact_total: '拨打电话',
    shipowner_total: '船东详情查询',
    create_time: '最新操作时间',

    normal: {
      info: '普货行为',

      shipowner_info: '船东信息',
      category_id: '航运分类',
      machine_id: '船只类型',
      start_harbor_info: '起始港信息',
      start_harbor_code: '起始港',
      end_harbor_info: '目的港信息',
      end_harbor_code: '目的港',
      schedule: '船期',
      create_time: '操作时间',
    },
    container: {
      info: '集装箱行为',

      start_harbor_info: '起始港信息',
      start_harbor_code: '起始港',
      end_harbor_info: '目的港信息',
      end_harbor_code: '目的港',
      search_time: '离港日期',
      create_time: '操作时间',
    },
    ship: {
      info: '船只查询行为',

      ship_name: '船名',
      create_time: '操作时间',
    },
    article: {
      info: '资讯浏览行为',

      title: '资讯标题',
      create_time: '操作时间',
    },

    contact: {
      info: '拨打电话行为',

      shipowner_info: '船东信息',
      manager_info: '联系人信息',
      mobile: '联系电话',
      create_time: '操作时间',
    },

    shipowner: {
      info: '船东详情行为',

      shipowner_info: '船东信息',
      create_time: '操作时间',
    }
  },

  contract: {
    info: '合约信息',

    contract_name: '合约名称',
    valid_time: '合约有效期',
  },

  market: {
    info: '销售信息',

    first_level_rate: '一级分销比例',
    second_level_rate: '二级分销比例',
    market_status: '销售状态',
  },

  setting: {
    exchange_total: '兑换次数'
  }
}
