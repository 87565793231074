export const role = {
  list: '身份列表',
  from: '身份操作',
  title: '身份名称',
  content: '身份描述',
  distribution: '分配身份',

  menu: '菜单权限',
  interface: '接口权限',

  rules: {
    title: {
      require: '身份名称不能为空',
      length: '长度在 1 到 50 个字符',
    },
  },

  permission: {
    list: '权限列表',
    from: '权限操作',
    info: '权限',

    name: '权限名称',
    title: '权限标题',
    value: '权限内容',

    rules: {
      role_id: {
        require: '角色不能为空',
      },
      name: {
        require: '权限名称不能为空',
      },
      title: {
        require: '权限标题不能为空',
      },
      value: {
        require: '权限内容不能为空',
      },
    },
  }
}
